.main_head {
  /* background: linear-gradient(to right, hsl(0, 30%, 95%, 0.3), hsl(0, 30%, 95%, 0.1), hsl(270, 29%, 97%, 0.5)); */

}
.head {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;

}
.payment {
	border-radius: 20px;  background: linear-gradient(to right, hsl(0, 30%, 95%, 0.9), hsl(0, 30%, 95%, 0.7), hsl(270, 29%, 97%, 0.9));
	box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.75);
}

.FormGroup {
	margin: 0 15px 20px;
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #829fff;
	border-radius: 4px;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

button.btn-payment {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 40px 15px 0;
	background-color: #f6a4eb;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

/* button:focus {
	background-color: #d782d9;
} */

button:active {
	background-color: #d782d9;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
		inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}

@media (max-width: 566px) {
	.payment {
		width: auto;
	}
}